.modal-dialog.login .modal-content {
    width: 500px;
    padding: 50px 20px 50px;
    box-shadow: 0px 0px 4px 0px #888888;
    background-color: #f5f5f5;
    border: none;
}
form.login-form .mail-inp, form.login-form .pass-inp {
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
form.login-form .mail-inp::placeholder, form.login-form .pass-inp::placeholder{
    color: #808080;
}
.login-form .signin-btn .cta_btn [type=submit] {
    font-size: 14px;
    cursor: pointer;
    text-transform: uppercase;
    font-family: "Poppins", sans-serif;
}
form.login-form {
    padding: 0 30px 20px;
}
form.login-form .forgot-pass h5{
    font-size: 12px;
    font-weight: 400;
    color: var(--primary_color);
    text-decoration: underline;
}
form.login-form .forgot-pass {
    margin: 10px 0 0;
}